import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ScrollToTop from './Scroll';
import Login from './components/Authentication/Login/Login';
import Home from './components/AdminDashboard/Home/Home';

function App() {
  return (
    <Router>
       <ScrollToTop/>
      <Routes>
       <Route path="/" element={<Login />} />
       <Route path="/dashboard" element={<Home />} />
      </Routes>
  </Router>
  );
}

export default App;
