import React from 'react'
import Sidebar from '../Reusable/Sidebar/Sidebar'
import { useSelector } from "react-redux";
const Home = () => {
    const userData = useSelector(state => state.userLogin.userInfo);
    const firstName = userData.user.first_name;
    const lastName = userData.user.last_name;
  return (
    <div style={{display:'flex'}}>
      <Sidebar/>
      <h1>Welcome {firstName}  {lastName}</h1>
    </div>
  )
}

export default Home
