import React from 'react';
import { Link, useLocation } from "react-router-dom";
import logo from '../../../../assets/adminlogo.svg';
import './Sidebar.scss';
import home from '../../../../assets/homeicon.svg';
import users from '../../../../assets/usersicon.svg';
import cont from '../../../../assets/contributions.svg';
import iconfour from '../../../../assets/iconfour.svg';
import subs from '../../../../assets/subs.svg';
const Sidebar = () => {
    
    const { pathname } = useLocation();
   
    const sideBarLinks = [
        {
        //   name: "dashboard",
          path: "/dashboard",
          icon:home
        },
        {
        //   name: "users",
          path: "/news-dsb",
            icon:users
        },
        {
        //   name: "Datasets",
          path: "/dataset-dsb",
             icon:cont
     
        },
        {
        //   name: "Data Visualisations",
          path: "/data/vis-dsb",
             icon:iconfour
        
        },
        {
        //   name: "Reports",
          path: "/reports-dsb",
             icon:subs
        
        },
        
      ];
 
  return (
    <div className='sdb-cont'>
        <div>
            <div className='sdb-cont-logo'>
                <img src={logo} alt="" />
            </div>

        
            
            {sideBarLinks.map((item, i) => (
                <Link style={{textDecoration:"none"}}  to={item.path} key={i}>
                <div className={pathname===item.path ? 'sdb-cont-pg alt':'sdb-cont-pg'}>
                    <div>
                    <img 
                    src={item.icon}
                    alt="icon"
                    />
                    </div>
                    
                    {/* <h4>{item.name}</h4> */}
                </div>
                </Link>
            ))}

            <div className='sdb-line'>

            </div>

        </div>

       <div>
              <div className='sdb-img' >
                <img src="https://images.pexels.com/photos/709143/pexels-photo-709143.jpeg?auto=compress&cs=tinysrgb&w=600" alt="profile" />
              </div>       
       </div>
      
    </div>
  )
}

export default Sidebar
